import createApp from '@shopify/app-bridge';
import { getSessionToken } from '@shopify/app-bridge-utils';
import { initializeWebVitals } from '@shopify/app-bridge/actions/WebVitals/actions';
import axios from 'axios';

import { baseUrl } from 'config/baseUrl';
import { PATHS } from 'config/constants';
import { handleErrorPayload } from 'utils/handleErrorPayload';

import { getproductadaptour } from 'adaptors/campagin/product';
import { getCampaignsAdaptors } from 'adaptors/campaign/list.adaptor';
import { getCampaignReportByStatusAdaptor } from 'adaptors/dashboard/status-report.adaptor';
import { plans } from 'adaptors/plans/plans-adaptor';
import {
  activateCampaign,
  createAndActivateCampaign,
  createCampaign,
  deactivateCampaign,
  deleteCampaign,
  getCampaign,
  getCampaignProducts,
  getCampaigns,
  updateAndActivateCampaign,
  updateCampaign,
} from 'rest/dist/gen/api/campaigns/campaigns';
import { getTopFiveCampaigns, orderReport } from 'rest/dist/gen/api/dashboard/dashboard';
import { getPlans, upgradePlan } from 'rest/dist/gen/api/pricing/pricing';
import {
  getProductTags,
  getProductTypes,
  getShopInfo,
  getVendors,
  setcartEmbedSettings,
  setShopStorage,
} from 'rest/dist/gen/api/shops/shops';
import { ShopStorageDto } from 'rest/dist/gen/models';

import { saveCampaignAdaptor } from 'adaptors/campaign/save.adaptor';

import { singleCampaignFormAdaptor } from 'adaptors/campaign/get.adaptor';
import { plansDiscount } from 'adaptors/plans/plans-discounted';
import { getSettingDataAdaptor, setSettingDataAdaptor } from 'adaptors/setting/setting-adaptors';
import { getSettings, setSettings } from 'rest/dist/gen/api/settings/settings';
import { sendSupportMail } from 'rest/dist/gen/api/supports/supports';

class Api {
  constructor(baseUrl) {
    this.axios = axios.create();
    this.axios.defaults.baseURL = baseUrl;
    this.axios.defaults.timeout = 90000;
    this.axios.defaults.headers['Content-Type'] = 'application/json';
  }

  setAppBridge(host, shopDomain, loggedInAsUser = false) {
    this.loggedInAsUser = loggedInAsUser;

    if (!loggedInAsUser) {
      this.app = createApp({
        apiKey: process.env.REACT_APP_API_KEY,
        host,
        forceRedirect: false,
      });

      try {
        initializeWebVitals(this.app);
      } catch (error) {
        console.error(error);
      }
    }

    this.axios.interceptors.request.use((config) => {
      if (loggedInAsUser) {
        config.headers['Authorization'] = this._getToken();
        config.headers['admin-login'] = true;
        config.headers['shop-domain'] = this._getShopDomain();
        return config;
      }

      return getSessionToken(this.app).then((token) => {
        config.headers['shopify-authorization'] = `Bearer ${token}`;
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['shop-domain'] = shopDomain;
        return config;
      });
    });
  }

  _getToken() {
    let token;
    if ('sessionStorage' in window) token = sessionStorage.getItem('loggedInAsUserToken');
    if (!token) return '';
    // return token;
    return `Bearer ${token}`;
  }

  _getShopDomain() {
    let shopDomain;
    if ('sessionStorage' in window) shopDomain = sessionStorage.getItem('loggedInAsUserShopDomain');
    if (!shopDomain) return '';
    return shopDomain;
  }

  _handleErrors(err) {
    if (err?.response?.status === 401) {
      if (this.loggedInAsUser) {
        window.open(window.location.origin + PATHS.LOGIN + '?message=INVALID_TOKEN', '_self');
      } else {
        // What to do in shopify iframe
      }
    }

    const errorMessage = handleErrorPayload(err);
    return Promise.reject(errorMessage);
  }

  async get(...params) {
    return this.axios.get(...params).catch((err) => this._handleErrors(err));
  }

  async post(...params) {
    return this.axios.post(...params).catch((err) => this._handleErrors(err));
  }

  async patch(...params) {
    return this.axios.patch(...params).catch((err) => this._handleErrors(err));
  }

  async put(...params) {
    return this.axios.put(...params).catch((err) => this._handleErrors(err));
  }

  async delete(...params) {
    return this.axios.delete(...params).catch((err) => this._handleErrors(err));
  }

  // Authentication
  loginAsUser(data) {
    return this.post(`/support/login/panel/`, data);
  }

  // Plans
  async getPlans() {
    return plans(await getPlans());
    // return this.get(`/billing/plans/`);
  }

  async upgradePlan(params) {
    /** @type {import('rest/dist/gen/models').UpgradePlanDto} */
    var mymodel = {
      planId: params.planId,
      period: params.selectedperiod === 'annually' ? 'annual' : 'monthly',
      discountCode: params.discountCode,
    };
    var result = await upgradePlan(mymodel);
    return result;
    // return this.get(`/billing/updatePlan${params}`);
  }

  async orderRevenueReport(params) {
    const data = await orderReport(params);
    const mapedData = data.data.map((x) => ({ date: x._id, revenue: x.revenue }));

    return { data: { data: mapedData, total: data.totalRevenue, success: true, message: 'ok' } };
    // return this.get(`api/auction/orderRevenueReport${params}`);
  }

  async orderCountReport(params) {
    const data = await orderReport(params);
    const mapedData = data.data.map((x) => ({ date: x._id, count: x.count }));

    return { data: { data: mapedData, total: data.totalCount, success: true, message: 'ok' } };
    // return this.get(`api/auction/orderCountReport${params}`);
  }

  cancelPlan() {
    return this.get(`/app-subscription/cancel/`);
  }
  getProductList() {
    return this.get(`/api/auction/productList/`);
  }
  getCollectionList() {
    return this.get(`/api/auction/collectionList/`);
  }

  applyDiscount(discountCode) {
    return this.get(`/discounts/${discountCode}/`);
  }

  // Shop Info
  getShopInfo() {
    return this.get('/api/shop/shopInfo');
  }
  getShopPlanInfo() {
    return this.get('/api/shop/shopPlanInfo');
  }
  getPermission() {
    return this.get('/api/shop/getPermission/');
  }

  getIsInstalledShop(shop) {
    return this.get('/api/shop/getIsInstalledShop?shop=' + shop);
  }

  async createAuction(data) {
    const campaign = saveCampaignAdaptor(data);

    if (data.id) {
      if (data.isActive) {
        await updateAndActivateCampaign(data.id, campaign);
      } else {
        if ([13, 5].includes(data.originalStatus)) {
          await deactivateCampaign(data.id);
        }
        await updateCampaign(data.id, campaign);
      }
    } else {
      if (data.isActive) {
        let res = await createAndActivateCampaign(campaign);
        data.id = res.data.id;
      } else {
        let res = await createCampaign(campaign);
        data.id = res.data.id;
      }
    }
    return { data: { data: data, showLimitationWarning: false, success: true, message: 'ok' } };
    // return this.post('/api/auction/saveAuction/', data);
  }
  async getAuction(auctionId) {
    const data = await getCampaign(auctionId);
    const res = singleCampaignFormAdaptor(data.data);
    return { data: res };
    // return this.get(`/api/auction/getAuction?auctionId=${auctionId}`);
  }
  async getAuctionProducts(auctionId) {
    return getproductadaptour(await getCampaignProducts(auctionId, { page: 1, pageSize: 100 }));
    // return this.get(`/api/auction/getAuctionWithProducts?auctionId=${auctionId}`);
  }
  async getAuctions() {
    const campaigns = await getCampaigns({ pageSize: 200 });
    const adaptedData = getCampaignsAdaptors(campaigns.data);
    return { data: adaptedData };
    // return this.get(`/api/auction/getAuctions`);
  }
  async getTagList() {
    const tags = await getProductTags();
    return { data: { ...tags, success: true } };
    // return this.get(`/api/common/resource/tagList`);
  }
  async getVendorList() {
    const vendors = await getVendors();
    return { data: { ...vendors, success: true } };
    // return this.get(`/api/common/resource/vendorList`);
  }
  async getProductTypeList() {
    const productTypes = await getProductTypes();
    return { data: { ...productTypes, success: true } };
    // return this.get(`/api/common/resource/productTypeList`);
  }
  async disableAuctions(data) {
    var lenofids = data.auctionIds.length;
    for (let index = 0; index < lenofids; index++) {
      const element = data.auctionIds[index];
      await deactivateCampaign(element);
    }
    return { data: { success: true } };
    // return this.post(`/api/auction/disableAuction/`, data);
  }
  async enableAuctions(data) {
    var lenofids = data.auctionIds.length;
    for (let index = 0; index < lenofids; index++) {
      const element = data.auctionIds[index];
      await activateCampaign(element);
    }
    return { data: { success: true } };
    // return this.post(`/api/auction/enableAuction/`, data);
  }
  archiveAuctions(data) {
    return this.post(`/api/auction/archiveAuctions/`, data);
  }
  unArchiveAuctions(data) {
    return this.post(`/api/auction/unarchiveAuctions/`, data);
  }
  async deleteAuctions(data) {
    var lenofids = data.auctionIds.length;
    for (let index = 0; index < lenofids; index++) {
      const element = data.auctionIds[index];
      var result = await deleteCampaign(element);
    }
    return { data: { success: true } };
  }
  getEstimateProducts(data) {
    return this.post(`/api/auction/estimateProducts/`, data);
  }
  getEstimateProductsResult(params) {
    return this.get(`/api/auction/estimateProductsResult${params}`);
  }
  getNotifications(params) {
    return this.get(`/api/common/getNotifications${params}`);
  }
  setNotificationClose(data) {
    return this.post(`/api/common/setNotificationClose/`, data);
  }
  setNotificationView(data) {
    return this.post(`/api/common/setNotificationView/`, data);
  }
  getOption(params) {
    return this.get(`/api/common/getOption${params}`);
  }
  async getPlansWithDiscount(params) {
    /** @type {import('rest/dist/gen/models').GetPlansParams} */
    var mymodel = {
      discountCode: params,
    };
    try {
      var resultdiscount = await getPlans(mymodel);
      if (resultdiscount.statusCode == 404) {
        var modeldata = {
          data: {
            success: false,
            message_key: 'msg_discount_code_not_valid',
            message: 'Discount Code is not Valid',
          },
        };
        return modeldata;
      }
      // if (resultdiscount.data[3].isCurrentPlan) {
      //   var modeldata = {
      //     data: {
      //       success: false,
      //       message_key: 'msg_discount_code_error_current_plan',
      //       message: 'Invalid code for current plan/cycle',
      //     },
      //   };
      //   return modeldata;
      // }
      return plansDiscount(resultdiscount);
    } catch (error) {
      var modeldata = {
        data: {
          success: false,
          message_key: 'msg_discount_code_not_valid',
          message: 'Discount Code is not Valid',
        },
      };
      return modeldata;
    }
    // return this.get(`/billing/plansWithDiscount${params}`);
  }
  async getShopSetting() {
    const settingsData = getSettingDataAdaptor(await getSettings());
    return settingsData;
  }
  async saveShopSetting(data) {
    const saveSettingData = await setSettings(setSettingDataAdaptor(data));
    return saveSettingData;
  }
  wasItHelpful(data) {
    return this.post(`/api/common/helpfulPage/`, data);
  }
  sendSupportEmail(data) {
    if (data.subject == '') {
      data.subject = 'Campaign creation';
    }
    return sendSupportMail(data);
    // return this.post(`/api/common/sendSupportEmail/`, data);
  }
  async updateCampaignEndDate(data) {
    // const campaign = saveCampaignAdaptor(data);
    await deactivateCampaign(data.auctionId);
    return { data: { success: true } };

    // return this.post(`/api/auction/updateCampaignEndDate/`, data);
  }

  async getCampaignReportByStatus() {
    const data = await getCampaignReportByStatusAdaptor();
    return { data };

    // return this.get(`api/auction/dashboardStatus`);
    // return campaignStatusDetail();
  }
  async getTopFiveCampaign() {
    const campaigns = await getTopFiveCampaigns();
    const adaptedData = getCampaignsAdaptors(campaigns.data);
    return { data: adaptedData };
    // return this.get(`api/auction/getTopFiveCampaign`);
  }
  getFaqList() {
    return this.get(`/api/common/getFAQList`);
  }
  campaignForceStop(id) {
    return this.get(`/api/auction/forceStop?auctionId=${id}`);
  }
  onboarding(data) {
    /** @type {ShopStorageDto[]} */
    var dtoshopstorage = [];
    if (
      data.onboardingFirstCampaign == true
      // data == 'onboardingFirstCampaign'
    ) {
      /** @type {ShopStorageDto} */
      var mymodel = { key: 'onboardingFirstCampaign', value: true };
      dtoshopstorage.push(mymodel);
    }
    if (
      data.onboardingActivateCampaign == true
      // data == 'onboardingActivateCampaign'
    ) {
      /** @type {ShopStorageDto} */
      var mymodel = { key: 'onboardingActivateCampaign', value: true };
      dtoshopstorage.push(mymodel);
    }
    if (
      data.onboardingNeedUpgradeCampaign !== undefined
      // data == 'onboardingNeedUpgradeCampaign'
    ) {
      /** @type {ShopStorageDto} */
      var mymodel = {
        key: 'onboardingNeedUpgradeCampaign',
        value: data.onboardingNeedUpgradeCampaign,
      };
      dtoshopstorage.push(mymodel);
    }
    if (
      // data == 'onboardingThemeExtension'
      data.onboardingThemeExtension == true
    ) {
      /** @type {ShopStorageDto} */
      var mymodel = { key: 'onboardingThemeExtension', value: true };
      dtoshopstorage.push(mymodel);
    }
    if (
      data.onboardingThemeExtension2 == true
      // data == 'onboardingThemeExtension2'
    ) {
      /** @type {ShopStorageDto} */
      var mymodel = { key: 'onboardingThemeExtension2', value: true };
      dtoshopstorage.push(mymodel);
    }
    if (
      data.onboardingDismiss == true
      // data == 'onboardingDismiss'
    ) {
      /** @type {ShopStorageDto} */
      var mymodel = { key: 'onboardingDismiss', value: true };
      dtoshopstorage.push(mymodel);
    }

    if (
      data.onboardingFinalEnableCheck == true
      // data == 'onboardingDismiss'
    ) {
      /** @type {ShopStorageDto} */
      var mymodel = { key: 'onboardingFinalEnableCheck', value: true };
      dtoshopstorage.push(mymodel);
    }

    return setShopStorage(dtoshopstorage);
    // return this.post(`/api/shop/onboarding/`, data);
  }
  async getCartEmbedSetting() {
    const data = await getShopInfo();
    return { data };
  }
  async setCartEmbedSetting(data) {
    await setcartEmbedSettings(data);

    // return { data: res };
    return { data: { success: true } };
  }
}

export const api = new Api(baseUrl);
